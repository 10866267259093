.main--about {
  .section-about {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);

    &__desc {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      p {
        padding: 0 3rem;
        &::first-letter {
          font-family: "Merienda", cursive;
        }
      }
    }

    &__img {
      height: 50rem;
      border: 1px solid #eee;

      &.img-1 {
        background-image: url(../img/tours/alpen/alpen-1280x740-md-2.jpg);
        background-size: cover;
      }

      &.img-2 {
        background-image: url(../img/tours/armenien/armenien-1280x740-md-1.jpg);
        background-size: cover;
      }
    }
  }

  .section-stories {
    position: relative;
    height: 80rem;

    h2 {
      @include before-after;
    }
  }
}