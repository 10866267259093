.submenu {

  &__list {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    // height: 15vh;

    list-style-type: none;

    li {
      margin: 3px 0;
    }
  }  

  &__link {
    text-decoration: none;
    color: $color-grey-dark-3;
    display: inline-block;
    padding: 0.5rem 3rem;
    margin-left: 5px;
    border: 1px solid $color-secondary-dark;
    @include btn-with-rounded-corner;

    &:hover {
      background-color: rgba($color-secondary-light, 0.7);
      transition: all .7s ease-in-out;
    }
    &.active {
      background-color: $color-secondary-light;
    }
  }
}