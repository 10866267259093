* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; // 1rem = 10px
}

html, body {
  height: 100%;
}

body {
  
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  line-height: 1.6;
  color: $color-grey-dark-2; 
  font-weight: 400;
  font-size: 1.6rem;

  min-height: 100vh;
  overflow-x: hidden;
}

h1, h2, h3, h4 {
  font-family: 'Merienda', cursive;
  text-align: center;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 2.5rem;
}

h3 {
 font-size: 2rem;
}

h4 {
  font-size: 1.6rem;
}

.container {
  width: 80%;
  max-width: 114rem;
  margin: 0 auto;
}