.btn {
  &[disabled] {
    cursor: not-allowed !important;
    opacity: 0.3;
  }

  &,
  &:link,
  &:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 1.2rem 4rem;
    display: inline-block;
    transition: all 0.2s;
    position: relative;
    font-size: $default-font-size;
    letter-spacing: 0.1rem;
    @include btn-with-rounded-corner;

    //Change for the <button> element
    border: none;
    cursor: pointer;
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba($color-black, 0.2);

    &::after {
      transform: scaleX(1.4) scaleY(1.6);
      opacity: 0;
    }
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba($color-black, 0.2);
  }

  &--white {
    @include setBtnColor($color-white, $color-grey-dark);

    &:hover {
      background-color: $color-white;
    }
  }

  &--grey {
    @include setBtnColor($color-grey-dark, $color-white);
  }

  &--blue {
    @include setBtnColor($color-primary, $color-white);
  }

  &--orange {
    @include setBtnColor($color-secondary-dark, $color-white);
    // background-color: $color-secondary-dark;
    // color: $color-white;

    // &::after {
    //   background-color: $color-secondary-dark;
    // }

    &:hover {
      background-color: $color-secondary;
    }
  }

  &::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.4s;
    @include btn-with-rounded-corner;
  }

  &--icon {
    padding: 1rem 2rem !important;
    margin-right: 0.5rem;
  }

  &--nav-top {
    font-size: 1.2rem !important;
    font-weight: bold;
    padding-right: 2rem !important;
    color: $color-black;

    i {
      font-size: 1.6rem !important;
    }
  }

  &--animated {
    animation: moveInBottom 0.5s ease-out 0.75s;
    animation-fill-mode: backwards;
  }
}

.btn-text {
  &:link,
  &:visited {
    font-size: $default-font-size;
    color: $color-primary;
    display: inline-block;
    text-decoration: none;
    border-bottom: 1px solid $color-primary;
    padding: 3px;
    transition: all 0.2s;
  }

  &:hover {
    background-color: $color-primary;
    color: $color-white;
    box-shadow: 0 1rem 2rem rgba($color-black, 0.15);
    transform: translateY(-2px);
  }

  &:active {
    box-shadow: 0 0.5rem 1rem rgba($color-black, 0.15);
    transform: translateY(0);
  }
}
