.nav-top {
  display: none;
  @media (min-width: $bp-medium) {
    display: block;
  }
  border-bottom: 1px solid #ddd;
  .nav-top-list {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 4rem;
    list-style-type: none;
    li {
      a {
        text-decoration: none;
        color: $color-grey-dark-2;
      }
      i.fa {
        color: $color-primary;
      }
      &:last-child {
        display: none;
        @media (min-width: $bp-large) {
          display: block;
        }
      }
    }

    a {
      text-decoration: none;
      color: $color-grey-dark-2;
    }
  }
}
