.tab {
  padding-bottom: 5rem;

  &__article {
    padding: 2rem 0 0;
    border-top: 1px solid #ddd;

    table {
      border: 1px solid lightgrey;
      border-collapse: collapse;
      width: 100%;
      margin: 2rem auto 0;

      td, th {
        border: 1px solid lightgrey;
        padding: .5rem 1rem;
        text-align: center;
      }
    }

    .img-container {
      display: flex;
      justify-content: center;
      margin: 5px;
      img {
        width: 90%;
      }
    }
  }
  
  &__item {
    display: inline-block;
    margin: 0 0 -1px;
    padding: 15px 25px;
    font-weight: 600;
    text-align: center;
    color: #bbb;
    border: 1px solid transparent;

    &:hover {
      color: #888;
      cursor: pointer;
    }

    &.active {
      color: #555;
      border: 1px solid #ddd;
      border-top: 2px solid $color-secondary-dark;
      border-bottom: 1px solid $color-white;
    }

    &::before {
      font-family: fontawesome;
      font-weight: normal;
      margin-right: 10px;
    }
  }
}
