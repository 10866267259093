.jumbotron { 
  position: relative;
  width: 100%;
  height: 15rem;
  margin-top: 6rem;
  @media (min-width: $bp-medium) {
    margin-top: 10rem;
  }

  color: white;
  font-size: 1.8rem;
  overflow: hidden;

  h1 {
    padding: 2rem 0 1rem;
  }

  .breadcrumb {
    color: $color-white;
    &__link {
      font-size: 1.6rem;
      color: inherit;
      text-decoration: none;
    }
  }

  &--destination {
    background-image: linear-gradient(rgba($color-secondary-light, 0.8), rgba($color-secondary-dark, 0.8)), url(../img/jumbotron/jumbotron-2.jpg);

    background-size: cover;
    background-position: top;
    background-attachment: fixed;

    @include setBreadcrumbHover($color-hover-1);
  }

  &--tourtype {
    background-image: linear-gradient(rgba($color-primary, 0.8), rgba($color-primary-dark, 0.8)), url(../img/jumbotron/jumbotron-2.jpg);

    background-size: cover;
    background-position: top;
    background-attachment: fixed;

     @include setBreadcrumbHover($color-hover-2);
  }

  &--tours,
  &--privacy-policy {
    background-image: linear-gradient(rgba($color-primary-light, 0.8), rgba($color-primary-dark, 0.8)), url(../img/jumbotron/jumbotron-2.jpg);
    background-size: cover;
    background-position: top;

    @include setBreadcrumbHover($color-hover-2);
  }

  &--booking {
    background-image: linear-gradient(rgba($color-primary, 0.8), rgba($color-primary-dark, 0.8)), url(../img/jumbotron/jumbotron-2.jpg);
   
    background-size: cover;
    background-position: top;
    background-attachment: fixed;

    @include setBreadcrumbHover($color-hover-2);
  }

 

  &--about,
  &--tour-details {
    background-image: linear-gradient(rgba($color-primary-light, 0.8), rgba($color-primary-dark, 0.8)), url(../img/jumbotron/jumbotron-8.jpg);
    background-size: cover;
    background-position: center;

    @include setBreadcrumbHover($color-hover-2);
  }

  &--contact {
    background-image: linear-gradient(rgba($color-primary-light, 0.8), rgba($color-primary-dark, 0.8)), url(../img/jumbotron/jumbotron-8.jpg);
    background-size: cover;
    background-position: center;

    @include setBreadcrumbHover($color-hover-2);
  }

  &--admin,
  &--login,
  &--register {
    margin-top: 6rem !important;
    background-image: linear-gradient(rgba($color-secondary-light, 0.8), rgba($color-secondary-dark, 0.8)), url(../img/jumbotron/jumbotron-2.jpg);

    background-size: cover;
    background-position: top;
    background-attachment: fixed;
  }

  &--tours-be,
  &--bookings-be {
    margin-top: 6rem !important;
    background-image: linear-gradient(rgba($color-primary-light, 0.8), rgba($color-primary-dark, 0.8)), url(../img/jumbotron/jumbotron-2.jpg);
    background-size: cover;
    background-position: center;
  }

  &--booking-details-be,
  &--tour-details-be {
    margin-top: 6rem !important;
    background-image: linear-gradient(rgba($color-primary-light, 0.8), rgba($color-primary-dark, 0.8)), url(../img/jumbotron/jumbotron-2.jpg);
    background-size: cover;
    background-position: center;
  }
}