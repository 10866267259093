@mixin button-hover {
  background-color: $color-secondary;
  transition: all 0.7s ease-in-out;
}

@mixin dot-hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.75);
  transition: all 0.7s ease-in-out;
}

@mixin btn-primary {
  color: white;
  background-color: $color-secondary;
  padding: 20px 40px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 18px;
  &:hover {
    cursor: pointer;
    background-color: darken($color-secondary, 10%);
    transition: all 0.7s ease-in-out;
  }
}

@mixin btn-with-rounded-corner {
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

@mixin setBtnColor($bg-col, $col) {
  background-color: $bg-col;
  color: $col;

  &::after {
    background-color: $bg-col;
  }

  &:hover {
    background-color: darken($bg-col, 10%);
  }
}

@mixin before-after {
  &::before {
    @extend %before-after;
    margin-right: 1rem;
  }
  &::after {
    @extend %before-after;
    margin-left: 1rem;
  }
}

@mixin highlightFirstLetter {
  &::first-letter {
    font-family: "Merienda", cursive;
    font-size: 3rem;
    color: $color-primary-dark;
  }
}

@mixin setMinHeight {
  min-height: calc(100vh - 25rem);
}

@mixin setBreadcrumbHover($col) {
.breadcrumb {
    &__link:hover {
      color: $col;
      transition: .5s ease-out;
    }
  }
}

@mixin setImages($img1, $img2, $img3, $img4, $img5, $img6) {
  &--1 {
    background-image: url($img1);
  }

  &--2 {
    background-image: url($img2);
  }

  &--3 {
    background-image: url($img3);
  }

  &--4 {
    background-image: url($img4);
  }

  &--5 {
    background-image: url($img5);
  }

  &--6 {
    background-image: url($img6);
  }
}

@mixin setBackgroundColor($colorLight, $colorDark) {
  background-image: linear-gradient(
    to right bottom,
    rgba($colorLight, 0.65),
    rgba($colorDark, 0.65)
  );
}

@mixin setDesc {
  display: none;
  @media (min-width: $bp-medium) {
    display: block;
  }
  width: 40rem;
  height: 20rem;
  background-color: rgba(237, 247, 249, 0.8);
  position: absolute;
  left: 5%;
  bottom: 15%;
  border-top-left-radius: 5rem;
  border-bottom-right-radius: 5rem;
}

@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// MEDIA QUERY MANAGER
/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/
@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    } //600px
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) {
      @content;
    } //900px
  }
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) {
      @content;
    } //1200px
  }
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    } //1800
  }
}
