.bird{
  transform:  scale(0.2) rotateZ(25deg) rotateY(180deg);

  &.oc{
    position: relative;
    height: 8rem;
    width: 8rem;
    left: 0;
    right: 0;
    margin: auto;
  }

  &.oc *{
      position: absolute;
  }

  .body{
    border-style:solid;
    border-color: transparent transparent transparent deepskyblue;
    border-width: 100px 0px 100px 100px;
    left: 30px;
  }

  .wing{
    left: -100px;
    border-style:solid;
    border-color: steelblue transparent transparent transparent;
    border-width: 100px 0 0 130px;
    transform-origin: right;
    animation-duration: 4s;
    animation-fill-mode: both;
    transform: rotate3D(0,1,0,180deg);
    z-index: 2;
    animation-name: a-wing;
    animation-delay: 1s;
  }

  .neck{
    border-style:solid;
    border-color:   transparent steelblue transparent transparent;
    border-width: 50px 50px 50px 50px;
    left: 30px;
  }
  .bird-head{
    left: 79px;
    border-style:solid;
    border-color:  transparent deepskyblue transparent transparent;
    border-width: 30px 50px 0px 0px;
    transform-origin: right;
    animation-duration: 4s;
    animation-delay: 1.5s;
    animation-fill-mode: both;
    transform: rotate3D(0,1,0,0deg);
    animation-name: b-head;
  }

  @keyframes b-head{
    0%{-webkit-transform: rotate3D(0,1,0,0deg);transform: rotate3D(0,1,0,0deg);}
    100%{-webkit-transform: rotate3D(0,1,0,-180deg);transform: rotate3D(0,1,0,-180deg);}
  }
  @keyframes a-wing{
    0%{-webkit-transform: rotate3D(0,1,0,180deg);transform: rotate3D(0,1,0,180deg);}
    100%{-webkit-transform: rotate3D(0,1,0,0deg);transform: rotate3D(0,1,0,0deg); }
  }
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translate(50%)
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }
  80% {
    transform: translateX(1rem);
  }
  100% {
    opacity: 1;
    transform: translate(0); 
  } 
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }
  80% {
    transform: translateX(-1rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes expand {
  0% {
    height: 0px;
  }
  25% {
    height: 100px;
  }
  50% {
    height: 200px;
  }
  75% {
    height: 300px;
  }
  100% {
    height: 400px;
  }
}

@keyframes collapse {
  0% {
    height: 400px;
  }
  25% {
    height: 300px;
  }
  50% {
    height: 200px;
  }
  75% {
    height: 100px;
  }
  100% {
    height: 0px;
  }
}