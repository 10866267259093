.search-bar {
  // height: 15rem;
  background-color: $color-primary;
  .search-form {
    padding: 2rem 0;
    width: 80%;
    height: 100%;
    margin: auto;

    &__container {
      height: 100%;
      margin-bottom: 1rem;

      display: block;
      @media (min-width: $bp-medium) {
        display: flex;
      }
      justify-content: space-around;
      align-items: flex-start;
      // flex-flow: column nowrap;
      // @media (min-width: $bp-medium) {
      //   flex-flow: row wrap;
      //   align-items: center;
      // }
    }

    &__input-group {
      display: flex;
      flex-direction: column;
      flex-basis: 80%;
      color: $color-white;

      label {
        margin: 1rem 0;
      }
    }

    &__input,
    &__select {
      font-size: 1.5rem;
      font-family: inherit;
      padding: 0.5rem 1rem;
      margin-right: 2rem;
      border-radius: 2px;
      background-color: rgba($color-white, 0.8);
      border: none;
      // width: 80%;
      @media (min-width: $bp-small) {
        width: inherit;
      }
    }

    .btn-wrapper {
      display: flex;
      justify-content: center;
      align-self: center;
      margin: 10px 0 0 0;
      padding: 3rem 0;
      @media (min-width: $bp-medium) {
        padding: 0;
      }

      &--bottom {
        align-self: flex-end;
      }

      button {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &--backend {
    // @media (min-width: $bp-medium) {
    //   min-height: 15vh;
    // }

    .search-form {
      &__container {
        align-items: center;
      }

      &__input-group:first-child {
        flex-basis: 80%;
      }

      &__input-group:nth-of-type(2) {
        flex-basis: 20%;
      }

      &__input,
      &__select {
        margin: 2rem 0;
      }

      button {
        margin: 10px;
        i {
          font-size: 1.8rem;
        }
      }
    }
  }
}
