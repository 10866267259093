.header {
  .navs-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 1.5rem 4rem rgba($color-black, 0.15);
    z-index: 9999;
  }

  &--backend {
    overflow: hidden;
  }
}

.main {
  background-color: $color-grey-light-1;

  p.general {
    text-indent: 2rem;
    margin-bottom: 1rem;
  }

  .section {
    h2 {
      @include before-after;
    }

    &--welcome {
      height: 20%;

      h2 {
        text-align: left;
      }

      p {
        text-indent: 3rem;
        @include highlightFirstLetter;
      }
    }

    &--features {
      background-image: linear-gradient(
          rgba(245, 245, 220, 0.9),
          rgba(240, 230, 140, 0.8)
        ),
      url(../img/jumbotron/jumbotron-7.jpg);
      background-size: cover;
      background-position: center;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
      height: 40%;
      min-height: 10rem;
    }

    &--sales {
      background-color: $bg-section-sale;
      h2 {
        text-align: center;
      }
    }

    &--news {
      background-color: $bg-section-news;

      h2 {
        text-align: right;
      }

      button {
        text-transform: uppercase;
      }
    }

    &--agb,
    &--datenschutz,
    &--impressum {
      h2, h3 {
        font-family: 'Open Sans', sans-serif;
        text-align: left;
      }

      h2 {
        font-size: 2rem;
      }

      h3 {
        font-size: 1.6rem;
      }

      h2::before,
      h2::after {
        display: none;
      }

      ul {
        list-style-type: none;
        margin-bottom: 2rem;
      }

      a {
        // text-decoration: none;
        color: $color-primary;
      }
    }

    &--backend {
      @include setMinHeight;

      h2 {
        text-align: left;
      }
    }
  }
}

.footer {
  background-color: $color-primary-dark;
  color: $color-white;

  .container {
    margin: 0 auto;
  }

  &__main {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    width: 70%;
    margin: auto;
    @media (min-width: $bp-medium) {
      flex-flow: row nowrap;
      align-items: center;
      width: 100%;
      margin: auto;
    }

    justify-content: space-between;
    list-style-type: none;
    .social i {
      padding-right: 1rem;
    }
  }

  &__column-title {
    img {
      width: 5rem;
    }
  }

  ul {
    list-style-type: none;

    li {
      padding: 1rem;

      & > img {
        vertical-align: bottom;
      }
    }
  }

  ul:last-child {
    flex-basis: 30%;
  }

  ul:not(:last-child) li:first-child {
    font-weight: bold;
    border-bottom: 1px solid $color-grey-dark-1;
  }

  a {
    text-decoration: none;
    color: white;
  }

  .subscribe {
    position: relative;

    &__input {
      font-family: inherit;
      padding: 0.8rem 1rem;
      border-radius: 2px;
      background-color: rgba($color-white, 0.8);
      border: none;
      width: 80%;

      &:focus {
        outline: none;
      }
    }

    &__button {
      height: 100%;
      padding: 0.8rem 1.6rem;
      border: 1px solid $color-secondary-dark;
      position: absolute;
      background-color: $color-secondary-dark;

      i {
        color: $color-white;
      }

      &:hover {
        background-color: $color-secondary;
        cursor: pointer;
      }
    }
  }

  &__copy {
    background-color: $color-primary;
    padding: 1rem 0;

    &-box {
      display: flex;
      flex-flow: column-reverse wrap;
     @media (min-width: $bp-medium) {
      flex-flow: row nowrap;
      // align-items: center;
      // width: 100%;
      // margin: auto;
    }
      justify-content: space-between;
      align-items: center;
      // height: 4rem;
      font-style: italic;
    }

    &-text {
      // float: left;

    }

    &-social {
      i {
        display: inline-block;
        padding: 1rem 1rem;
      }
    }

    &-img {
      height: 30px;
    }

    &--backend {
      background-color: $color-primary-dark;

    }
  }
}
