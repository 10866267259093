.form {
  height: 100%;

  padding: 3rem;
  @media (min-width: $bp-medium) {
    padding: 5rem;
  }

  &__group-container {
    @media (min-width: $bp-medium) {
      display: flex;
      justify-content: space-between;
      // flex-wrap: wrap;
    }
  }

  &__group {
    margin-top: 2rem;
    flex-basis: 49%;

    &--short {
      flex-basis: 32%;
    }

    @media only screen and (max-width: 37.5em) {
      flex-basis: 100%;
    }
  }

  &__set {
    padding: 2rem;
    border: 1px solid $color-white;
    margin-top: 1rem;
  }

  legend {
    padding: 0.5rem 2rem;
    background-color: darken($color-primary, 10%);
    color: white;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &__tour-name {
    font-family: "Merienda", cursive;
    font-weight: 400;
    font-size: 2rem;
    letter-spacing: 2px;
  }

  &__input,
  &__textarea,
  &__select {
    width: 100%;
    // display: block;
    font-size: 1.5rem;
    font-family: inherit;
    color: inherit;
    padding: 0.6rem 1rem;
    margin: 1rem 0;
    border-radius: 2px;
    background-color: rgba($color-white, 0.8);
    border: none;
    border-bottom: 2px solid transparent;
    transition: border 0.3s ease-in;
  }

  &__input {
    &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba($color-black, 0.1);
      border-bottom: 2px solid $color-primary;
    }
    &:focus:invalid {
      border-bottom: 2px solid $color-secondary-dark;
    }

    // &:required:focus {
    //   border-bottom: 2px solid $color-secondary-dark;
    // }
  }

  &__textarea {
    border: 1px solid transparent;

    &:focus {
      border: 1px solid $color-primary;
    }
  }

  [type="submit"] {
    display: block;
    margin: 5rem auto 2rem;

    &.input-login {
      margin: 2rem auto 4rem;
    }

    &.input-register {
      margin: 4rem auto;
    }
  }

  &__select {
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(../img/icons/svg/chevron-thin-down.svg);
    background-repeat: no-repeat;
    background-position: 98% 50%;
    background-size: 16px;
    border-radius: 0px;
    padding: 0.6rem 0.6rem 1rem;
    &[multiple],
    &#selected-tour {
      background-image: none;
    }
  }

  &__label {
    padding: 0.6rem;

    span {
      color: red;
    }
  }

  [type="checkbox"] {
    margin-left: 0.5rem;
    opacity: 0;
    visibility: hidden;
  }

  &__checkbox-label {
    position: relative;
    padding-left: 1rem;
    cursor: pointer;
  }

  &__checkbox-button {
    height: 2rem;
    width: 2rem;
    border: 3px solid $color-secondary-dark;
    display: inline-block;
    position: absolute;
    left: -2.05rem;
    top: 0.1rem;
  }

  &__checkbox-button-checked {
    position: absolute;
    left: -1.8rem;
    top: -0.3rem;
    opacity: 0;
    visibility: hidden;
  }

  &__checkbox-input:checked + &__checkbox-label > &__checkbox-button-checked {
    opacity: 1;
    visibility: visible;
  }

  &__checkbox-text {
    a {
      // color: darken(white, 10%);
      color: $color-secondary-dark;
    }
  }

  &--booking {
    width: 100%;
  }

  &__password-condition {
    i {
      color: red;
      font-size: 1.8rem;
    }
  }

  &--backend {
    width: 100%;
    padding-top: 2rem;
  }
}
