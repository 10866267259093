%prev-next {
  @media (min-width: $bp-medium) {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: rgb(247, 216, 216);
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
  }
}

%before-after {
  @media (min-width: $bp-medium) {
    content: "";
    position: relative;
    top: -0.8rem;
    display: inline-block;
    height: 1px;
    width: 8rem;
    background-color: $color-grey-light-5;
  }
}
