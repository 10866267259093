.table {
  &__row {

    @media(min-width: $bp-medium) {
      display: flex;
      justify-content: space-around;
    }

    &:nth-of-type(even) {
      background-color: lighten($color-secondary, 40%);
    } 
  }

  &__col {
    
    flex-basis: 30%;
    padding: 2rem;

    .thumb-box {
      display: block;
      margin-bottom: 2rem;
      outline: 3px solid transparent;
      outline-offset: 3px;
      text-decoration: none;
      color: black;

      &__img {
        width: 100%;
      }

      &__text {
        display: inline-block;
        margin-left: 3px;
        font-family: 'Merienda', cursive;
        font-size: 2rem;
      }

      &:hover {
        outline: 3px solid $color-primary;
        transition: all .5s;
      }
    }

    span {
      margin-right: 1rem;
    }
  }

  ul {
    list-style-type: none;
    // flex-basis: 40%;
  }
}