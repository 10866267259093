.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(35rem, min-content));
  grid-column: center-start / center-end;
  grid-gap: 6rem;
  justify-content: center;
}

.card {
  background-color: $color-card;
  border-radius: 3px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-decoration: none;
  color: inherit;
  grid-row-gap: 1.5rem;
  box-shadow: 0 1.5rem 4rem
  rgba(0, 0, 0, 0.15);

  &__img,
  &__img-desc {
    width: 100%;
    grid-row: 1 / 2;
    grid-column: 1 / -1;
    z-index: 1;
    border-radius: 3px;
    transition: all .5s;
  }
  
  &__name {
    grid-row: 2 / 3;
    grid-column: 1 / -1;
    z-index: 3;
    min-height: 6rem;

    width: 100%;
    font-size: 2rem;
    letter-spacing: 1px;
    text-align: center;
    padding: .5rem;
    background-color: darken($color-primary, 10%);
    opacity: .8;
    color: #fff;
    font-family: 'Merienda', cursive;
    font-weight: 400;
    transform: translateY(-50%);
    @include btn-with-rounded-corner;
  }

  &__name:hover {
    background-color: $color-primary-dark;
  }

  &__location,
  &__rooms,
  &__area,
  &__price {
    font-size: 1.5rem;
    margin: 1rem 0 2rem 4rem;
  }

  &__location,
  &__rooms {
    margin: 0 0 1rem 4rem;
  }

  &__btn {
    grid-column: 1 / -1;
    background-color: $color-secondary;

    a {
      text-decoration: none;
      color: $color-black;
    }

    &:hover {
      background-color: darken($color-secondary, 10%);
    }
  }

  i {
    color: $color-primary-dark;
  }

  object {
    text-align: center;
    a {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }
}