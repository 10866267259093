.u-center-text {
  text-align: center !important;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}

.u-margin-bottom-medium {
    margin-bottom: 4rem !important;

    @include respond(tab-port) {
        margin-bottom: 3rem !important;
    }
}

.u-margin-bottom-big { 
    margin-bottom: 8rem !important;

    @include respond(tab-port) {
        margin-bottom: 5rem !important;
    }
}

.u-margin-top-big {
  margin: 5rem;
}

.u-margin-top-huge {
  margin-top: 10rem !important;
}

/*Background*/

.u-bg-primary {
  background-color: $color-primary;
}

.u-bg-secondary {
  background-color: $color-secondary;
}

.u-bg-white {
  background-color: $color-white;
}

.u-bg-honeydew {
  background-color: honeydew;
}

/*****Spacing*****/
.u-p-3 {
  padding: 3rem;
}

.u-p-4 {
  padding: 4rem;
}

.u-p-5 {
  padding: 5rem;
}

.u-py-1 {
  padding: 1rem 0;
}

.u-py-2 {
  padding: 2rem 0;
}

.u-py-3 {
  padding: 3rem 0;
}

.u-py-4 {
  padding: 4rem 0;
}

.u-py-5 {
  padding: 5rem 0;
}

.u-pt-2 {
  padding-top: 2rem;
}

.u-pt-3 {
  padding-top: 3rem;
}

.u-pt-4 {
  padding-top: 4rem;
}

.u-pt-5 {
  padding-top: 5rem;
}

.u-pb-2 {
  padding-bottom: 2rem;
}

.u-pb-3 {
  padding-bottom: 3rem;
}

.u-pb-4 {
  padding-bottom: 4rem;
}

.u-pb-5 {
  padding-bottom: 5rem;
}

.u-pb-8 {
  padding-bottom: 8rem;
}

.u-m-1 {
  margin: 1rem;
}

.u-my-1 {
  margin: 1rem 0;
}

.u-my-2 {
  margin: 2rem 0;
}

.u-my-3 {
  margin: 3rem 0;
}

.u-my-4 {
  margin: 4rem 0;
}

.u-mb-1 {
  margin-bottom: 1rem;
}

.u-mb-2 {
  margin-bottom: 2rem;
}

.u-mb-2plus {
  margin-bottom: 2.5rem;
}

.u-mt-2 {
  margin-top: 2rem;
}

.u-mt-4 {
  margin-top: 4rem;
}

