.slideshow,
.slideshow-gallery {
  height: 80vh;
  // margin-top: 10rem; /*important for fixed menu*/
  margin-top: 0;
  @media (min-width: $bp-medium) {
    margin-top: 10rem;
  }
  overflow: hidden;
  position: relative;

  .carousel {
    height: 100%;
    width: 100%;
    margin: 0 auto;

    &__track-container {
      height: 100%;
      position: relative;
    }

    &__track {
      height: 100%;
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
    }

    &__desc {
      @include setDesc;

      p {
        padding: 0 3rem 4rem;
      }

      &-link {
        bottom: 2rem;
        right: -50%;
        text-decoration: none;
      }
    }

    &__caption {
      padding: 2rem 0;
    }

    &__button {
      &--prev,
      &--next {
        display: none;
        @media (min-width: $bp-medium) {
          display: block;
        }
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: auto;
        height: auto;
        padding: 16px;
        color: white;
        font-weight: bold;
        font-size: 28px;
        border-radius: 0 3px 3px 0;
        transition: all 0.5s ease-in;
        user-select: none;
        z-index: 1;
        -webkit-user-select: none;
      }

      &--next {
        right: 0;
        border-radius: 3px 0 0 3px;
      }

      &--prev:hover,
      &--next:hover {
        background-color: rgba($color-grey-dark-4, 0.8);
      }

      &--prev.disable,
      &--next.disable {
        color: $color-grey-dark-1;
      }
    }

    &__nav {
      background: $color-secondary;
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 10px 0;
      position: absolute;
      bottom: 0;
    }

    &__indicator {
      &--dot {
        &.current-slide {
          background-color: rgba($color-black, 0.75);
          &:hover {
            @include dot-hover;
          }
        }
        border: 0;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background-color: rgba($color-black, 0.3);
        margin: 0 12px;

        &:hover {
          @include dot-hover;
        }
      }

      &--img {
        object-fit: cover;
        &.current-slide,
        &:hover {
          opacity: 1;
        }
      }
    }

    .fade {
      -webkit-animation-name: fade;
      -webkit-animation-duration: 1.5s;
      animation-name: fade;
      animation-duration: 1.5s;
    }

    .hide {
      display: none;
    }

    .show {
      display: block;
    }

    .disable {
      svg {
        path {
          fill: grey;
        }
      }
    }
  }

  @-webkit-keyframes fade {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fade {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }
}

.slideshow-gallery {
  margin-top: 0;
  .carousel {
    &__track-container {
      &--gallery {
        height: 80%;
      }
    }

    &__caption {
      display: none;
      @media (min-width: $bp-medium) {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        text-align: center;
        background-color: rgba($color-grey-dark-4, 0.8);
        padding: 1.2rem;
        color: $color-white;
      }
    }

    &__nav {
      height: 20%;
      &--images {
        background: black;
        padding: 0;

        figure {
          margin: 0;
        }

        img {
          height: 100%;
          width: 100%;
          opacity: 0.6;
          cursor: pointer;
        }
      }
    }
  }
}

.no-js {
  .slideshow-gallery {
    .carousel {
      &__button {
        display: none;
      }
      &__nav {
        display: none;
      }
    }
  }
}
