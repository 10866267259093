.heading-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .heading-primary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: 0.1rem;
    color: $color-primary;
    &--first-part {
      animation-name: moveInLeft;
      animation-duration: 1s;
      animation-timing-function: ease-out;
    }
    &--second-part {
      color: darken($color-secondary, 10%);
      animation: moveInRight 1s ease-out;
    }
    &--first-part,
    &--second-part {
      display: none;
      @media (min-width: $bp-medium) {
        display: inline;
      }
    }
  }

  .moto {
    // position: absolute;
    // bottom: 1rem;
    color: $color-primary;
    font-family: "Merienda", cursive;
    font-size: 2rem;
  }
}
