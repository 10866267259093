.booking-info {
  @media (min-width: $bp-medium) {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
  }

  ul {
    list-style-type: none;
    li {
      padding: 0.5rem 0;

    }

    .street {
      display: flex;
      flex-flow: column wrap;

      span:last-child {
        text-indent: 2rem;
      }
    }

    .total-price {
      border-top: 1px solid lightgray;
      padding-top: 1rem;
    }
  }

  &__img-box {
    flex-basis: 40%;
    img {
      max-width: 100%;
    }
  }

  &__person-box {
    li:first-child {
      padding-left: 0.6rem;
      border-bottom: 1px solid lightgray;
      margin-bottom: 1rem;
    }
  }
}

.info-bar {
  p {
    display: inline-block;
    padding: 1rem;
  }

  div {
    float: right;
  }

  @include clearfix;

  &--right {
    text-align: right;
  }
}

.preview-upload {
  @media (min-width: $bp-medium) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .wrapper {
    padding-right: 1rem;
  }

  &__img {
    max-width: 15rem;
    margin-right: 1rem;
  }

  &__text {
    display: block;
    color: $color-white;
    @media (min-width: $bp-medium) {
      color: $color-black;
    }
  }

  &__browse {
    margin-top: 1rem;
  }
}
