.nav {
  height: 6rem;
  display: flex;
  justify-content: space-between;

  &__logo-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-basis: content;

    a {
      text-decoration: none;
      display: inline-block;
    }
  }
  
  &__logo-img {
    width: 6rem;
  }
  
  &__logo-text {
    font-family: 'Merienda', cursive;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 2.5rem;
    color: $color-primary;
  }

  .burger-toggler {
    display: none;

    &:checked + .menu {
      display: block;
    }
  }

  .menu {
    font-size: 14px;
    letter-spacing: 2px;
    list-style-type: none;

    &--horizontal {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex: 1;
    }

    &__item {
     
      border-top-left-radius: 1rem;
      transition: all .5s ease-in-out;

      &.active {
        background-color: $color-secondary;
        border-bottom: 3px solid $color-primary;
      }

      &:not(:last-child) {
        margin-right: 1.4rem;
      }

      &:hover {
        background-color: $color-secondary;
      }

      &:not(.active)::after {
        content: "";
        display: block;
        position: relative;
        // width: 0%;
        height: 3px;
        width: 100%;
        transform: scale(0, 1);
        background-color: $color-primary;
        transition: all ease-out .3s
      }

      &:hover::after {
        // width: 100%;
        transform: scale(1, 1);
      }

      &--container {
        position: relative;
      }

      &--dropdown {
        margin: 0 !important;
      }

      &:hover > ul {
        opacity: 1;
        visibility: visible;
      }

      &:hover .menu__toggler i:first-child {
        opacity: 0;
        visibility: hidden;
        width: 0;
      }

      &:hover .menu__toggler i:last-child {
        opacity: 1;
        visibility: visible;
        width: 100%;
      }
    }

    &__link {
      display: block;
      padding: 1rem 1.4rem;
      text-decoration: none;
      color: $color-black;
      font-weight: 700;
      border-top-left-radius: 1rem;

      &:hover {
        background-color: $color-secondary;
      }

      // &.active {
      //   border-bottom: 3px solid transparent;
      // }

      &--dropdown {
        padding-right: 4.5rem;
        text-decoration: none;
      }
    }

    &__dropdown {
      position: absolute;
      background-color: $color-secondary;
      list-style-type: none;
      opacity: 0;
      visibility: hidden;
      width: 100%;
      // width: 18rem;
    }

    &__toggler {
      position: relative;

      i {
        position: absolute;
        top: 3px;
        left: 1.5rem;
        transition: all .2s ease;
      }

      i:last-child {
        opacity: 0;
        visibility: hidden;
      }
    }

    @media (max-width: 930px) {
      &--horizontal {
        display: none;
      }

      &--vertical {
        position:absolute;
        top: 0;
        right: 0;
        width: 50%;
        background-color:rgba($color-primary, 0.9);
        text-align: center;
        z-index: 1;
      }

      .menu {
        &__item {
          margin-top: 2rem;
          margin-right: 0;

          &:last-child:hover::after {
            // width: 100%;
            transform: scale(0, 1);
          }
        
          &:first-child {
            margin-top: 5rem;
          }
    
          &:last-child {
            margin-bottom: 3rem;
          }

          &:hover > .menu__dropdown {
            height: 100%;
          }
        }

        &__link {
          color: $color-white;
    
          &.active {
            color: $color-black;
          }
  
          &:hover {
            color: $color-primary-dark;
          }

          &--1 {
            animation: moveInLeft 0.5s ease-in;
            animation-delay: .1s;
          }

          &--2 {
            animation: moveInRight .5s ease-in;
            animation-delay: .2s;
          }

          &--3 {
            animation: moveInLeft .5s ease-in;
            animation-delay: .3s;
          }

          &--4 {
            animation: moveInRight .5s ease-in;
            animation-delay: .4s;
          }

          &--5 {
            animation: moveInLeft .5s ease-in;
            animation-delay: .5s;
          }

          &--6 {
            animation: moveInRight .5s ease-in;
            animation-delay: .6s;
          }
        }

        &__dropdown {
          position: relative;
          height: 0;
          // max-height: 0;
        }
      }
    }
  }

  label {
    position: relative;

    .burger-bar {
      width: 30px;
      height: 30px;
      top: 15px !important;
      right: 15px !important;
      left: -45px !important;
      display: none;

      &__line {
        width: 28px;
        height: 3px;
        background-color: $color-primary;
        margin-bottom: 7px;
        transition: all 0.3s ease;
      }

      &__line--1.expanded {
        transform: rotate(-45deg) translate(-6.5px, 7.5px);
      }

      &__line--2.expanded {
        opacity: 0;
      }

      &__line--3.expanded {
        transform: rotate(45deg) translate(-6.5px, -7.5px);
      }

      @media (max-width: 930px) {
        display: block;
        cursor: pointer;
        position: absolute;
        top: 8px;
        right: 8px;
        background-color: transparent;
        z-index: 2;
      }

      &__line.expanded {
        background-color: white;
      }
    }
  }

  @media (max-width: 930px) {
    position: relative;
  }
}