.feedback {
  padding: 2rem 0;
  color: $color-grey-dark-2;

  p {
    font-weight: bold;
    text-align: center;
  }

  &__errors {
    position: relative;
    left: 20%;
  }

  li:first-child {
    font-weight: bold;
    padding-bottom: 1rem;
    list-style: none;
  }
 
  &--alert {
    background-color: lighten($color-secondary, 30%);

    i {
      color: red;
    }
  }
  &--info {
    background-color: lighten(#73b726, 40%);
  }
}
