.gallery {
  background-color: $color-grey-light-1;
  grid-column: full-start / full-end;

  display: grid;
  /*grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(7, 5vw);*/
  grid-template: repeat(7, 5vw) / repeat(8, 1fr);


  grid-gap: 1.5rem;
  padding: 1.5rem;

  &__item {
    &--0 {
      grid-row: 1 / span 2;
      grid-column: 1 / span 2;
    }

    &--1 {
      grid-row: 1 / span 3;
      grid-column: 3 / span 3;
    }

    &--2 {
      grid-row: 1 / span 2;
      grid-column: 6 / 7;
    }

    &--3 {
      grid-row: 1 / span 2;
      grid-column: 7 / -1;
    }

    &--4 {
      grid-row: 3 / span 3;
      grid-column: 1 / span 2;
    }

    &--5 {
      grid-row: 4 / span 2;
      grid-column: 3 / span 2;
    }

    &--6 {
      grid-row: 4 / 5;
      grid-column: 5 / 6;
    }

    &--7 {
      grid-row: 3 / span 2;
      grid-column: 6 / span 2;
    }

    &--8 {
      grid-row: 3 / span 3;
      grid-column: 8 / -1;
    }

    &--9 {
      grid-row: 6 / span 2;
      grid-column: 1 / 2;
    }

    &--10 {
      grid-row: 6 / span 2;
      grid-column: 2 / span 2;
    }

    &--11 {
      grid-row: 6 / span 2;
      grid-column: 4 / 5;
    }

    &--12 {
      grid-row: 5 / span 3;
      grid-column: 5 / span 3;
    }

    &--13 {
      grid-row: 6 / span 2;
      grid-column: 8 / -1;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transition: all .7s ease-in-out;
  }
  &__img:hover {
    transform: scale(1.25);
  } 
}