$color-primary: #1e94b1;
$color-primary-light: #add8e6;
$color-primary-dark: #033c4b;

$color-secondary: #febe16;
$color-secondary-light: #ffb900;
$color-secondary-dark: #f8910e;
$color-secondary-light-1: lightyellow;
$color-secondary-light-2: lightgoldenrodyellow;
$color-secondary-light-3: palegoldenrod;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;
$color-grey-light-3: rgb(237, 247, 249);
$color-grey-light-4: rgb(230, 230, 230);
$color-grey-light-5: #ccc;

$color-grey-dark: #777;
$color-grey-dark-1: #999;
$color-grey-dark-2: #444;
$color-grey-dark-3: #333;
$color-grey-dark-4: #222;

$color-white: #fff;
$color-black: #000;

$color-card: #e4eae9;

$color-hover-1: aqua;
$color-hover-2: yellow;

$default-font-size: 1.6rem;

$bg-section-sale: beige;
$bg-section-news: #ffffe0;

// RESPONSIVE BREAKPOINTS
$bp-largest: 75em; // 1200px
$bp-large: 60em; // 960px
$bp-medium: 45em; // 720px;
$bp-small: 30em; // 600px;
