.feature-box {
  background-color: rgba($color-white, .8);
  font-size: 1.5rem;
  margin: 3rem 0;
  padding: 2.5rem;
  text-align: center;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba($color-black, .15);
  transition: transform .3s;
  flex-basis: 25rem;
  flex-shrink: 0;
  text-decoration: none;
  h4 {
    margin: 2rem 0;
    color: $color-grey-dark-2;
  }

  p {
    color: $color-grey-dark-2;
  }

  &:hover {
    transform: translateY(-1.5rem) scale(1.03);
  }

  &--backend {
    flex-basis: 60%;

    &:hover {
      transform: none;
    }

    p {
      padding: 4rem;
      font-size: 2rem;
    }

    a,
    button{
      margin-right: 1rem;
      padding: 1.6rem 4rem;
    }
  }
}