.section-booking-pane {
  background-color: $color-grey-light-5;
  color: $color-grey-dark-4;
  
  .booking-content { 
    width: 50%;
    margin: auto;
    padding: 3rem 0;
    margin-bottom: 5rem;

   
    @media (min-width: $bp-medium) {
      width: auto;
      display: flex;
      justify-content: space-around;
      align-items: center;
    } 

    &__item {
      padding: 1rem 0;
    }
    
  }

  ul {
    list-style-type: none;
  }
}