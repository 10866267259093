.main--contact,
.main--login,
.main--register {
  .section-contact {
    .heading-secondary {
      @include before-after;
    }

    .container-contact {
      height: 80rem;
      padding: 4rem;
      background-image: linear-gradient(
        to right bottom,
        $color-primary-light,
        $color-primary-dark
      );

      &--backend {
        height: auto;
        @include setMinHeight;
      }
    }

    .contact {
      color: $color-black;
      @media (min-width: $bp-medium) {
        width: 80%;
        margin: auto;
        background-image: linear-gradient(
            105deg,
            rgba($color-primary-light, 0.9) 0%,
            rgba($color-primary-light, 0.9) 70%,
            transparent 70%
          ),
          url(../img/jumbotron/jumbotron-7.jpg);
        background-size: cover;
        background-attachment: fixed;
        border-radius: 3px;
        box-shadow: 0 1.5rem 4rem rgba($color-black, 0.2);
      }
    }
    &--backend {
      .login-help {
        display: flex;
        justify-content: flex-start;

        a {
          text-decoration: none;
          color: #444;
          padding: 0 1rem;

          &:first-child {
            padding-left: 0;
          }
        }

        span {
          font-weight: bold;
          color: #1e94b1;
        }
      }
    }
  }

  #map {
    width: 100%;
    height: 40rem;
  }
}
