.info-box {
  background-color: darken($color-primary, 10%);
  color: $color-white;

  @media (min-width: $bp-medium) {
    display: flex;
    flex-direction: row;
  }

  &__img-box {
    width: 100%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }

    &:hover figcaption {
      display: block;
    }

    figcaption {
      position: absolute;
      left: 0;
      bottom: 0;
      color: #333;
      width: 100%;
      background-color: rgba($color-white, 0.8);
      padding: 2rem;
      @include btn-with-rounded-corner;
      display: none;
    }
  }

  &__info {
    width: 50%;
    margin: auto;
    @media (min-width: $bp-medium) {
      display: inline-grid;
      grid-gap: 2rem;
      flex-basis: 30%;
      padding: 4rem;
    }

    list-style-type: none;

    li {
      padding: 1.4rem 0;
    }
  }
}
