.cards-category-container {
  max-width: 80%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, min-content));
  grid-auto-rows: 42rem;
  justify-content: center;
  grid-gap: 6rem;
}

.card-category {
  height: 42rem;
  position: relative;
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba($color-black, .15);
  transition: all .8s ease;

  &:hover {
    cursor: pointer;
    transform: translateY(-1.5rem) scale(1.03);
  }

  &__img {
    background-size: cover;
    background-position: center;
    height: 40rem;
    background-blend-mode: screen;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    
    &-dest {
      @include setImages(
        '../img/destinations/dest-1.jpg',
        '../img/destinations/dest-2.jpg',
        '../img/destinations/dest-3.jpg',
        '../img/destinations/dest-4.jpg',
        '../img/destinations/dest-5.jpg',
        '../img/destinations/dest-6.jpg'
      );
    } 
    &-tourtype { 
      @include setImages(
        '../img/tourtypes/tourtype-1.jpg',
        '../img/tourtypes/tourtype-2.jpg',
        '../img/tourtypes/tourtype-3.jpg',
        '../img/tourtypes/tourtype-4.jpg',
        '../img/tourtypes/tourtype-5.jpg',
        '../img/tourtypes/tourtype-6.jpg'
      );
    }
  }

  &__heading {
    font-size: 2.6rem;
    font-weight: 300;
    text-transform: uppercase;
    text-align: right;
    color: $color-white;
    position: absolute;
    bottom: -1rem;
    right: -2rem;
  }

  &__heading-span {
    padding: 1rem 1.5rem;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    @include btn-with-rounded-corner;

    &--1 {
      @include setBackgroundColor($color-secondary-light, $color-secondary-dark);
    }

    &--2 {
      @include setBackgroundColor($color-primary-light, $color-primary-dark);
    }

    &--3 {
      @include setBackgroundColor($color-tertiary-light, $color-tertiary-dark);
    }

    &--4 {
      @include setBackgroundColor($color-tertiary-light, $color-tertiary-dark);
    }

    &--5 {
      @include setBackgroundColor($color-primary-light, $color-primary-dark);
    }

    &--6 {
      @include setBackgroundColor($color-secondary-light, $color-secondary-dark);
    }
  }
}