/* merienda-regular - latin */
@font-face {
  font-family: "Merienda";
  font-style: normal;
  font-weight: 400;
  src: local("Merienda"), local("Merienda-Regular_0_wt"),
    url("../fonts/merienda/merienda-v8-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/merienda/merienda-v8-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* merienda-700 - latin */
@font-face {
  font-family: "Merienda";
  font-style: normal;
  font-weight: 700;
  src: local("Merienda Bold"), local("Merienda-Bold"),
    url("../fonts/merienda/merienda-v8-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/merienda/merienda-v8-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
