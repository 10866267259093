.section-booking {
  .container-booking {
    padding: 0;
    background-image: linear-gradient(
      to right bottom,
      $color-primary-light,
      lighten($color-primary-dark, 10)
    );
    @media (min-width: $bp-medium) {
      padding: 5rem;
      background-image: linear-gradient(
        to right bottom,
        $color-primary-light,
        $color-primary-dark
      );
    }
  }

  .booking {
    height: 100%;
    @media (min-width: $bp-large) {
      width: 80%;
    }
    margin: auto;
    display: flex;
    justify-content: stretch;
    align-items: flex-start;
    @media (min-width: $bp-medium) {
      background-image: linear-gradient(
        rgba($color-primary-light, 0.95),
        rgba($color-primary-light, 0.95)
      ),
      url(../img/jumbotron/jumbotron-2.jpg);
      background-size: cover;
      background-attachment: fixed;
      border-radius: 3px;
      box-shadow: 0 1.5rem 4rem rgba($color-black, 0.2);
    }
    .booking-info {
      background: lime;
    }
  }
}

